<script lang="ts" setup>
const { appData } = useApp()
</script>

<template>
  <div class="h-7 w-[100px] shrink-0 lg:h-12 lg:w-[140px]">
    <img
      v-if="appData.company.logo"
      :alt="appData.company.name"
      class="block h-full w-full object-contain object-left"
      loading="lazy"
      :src="appData.company.logo"
    >
  </div>
</template>
