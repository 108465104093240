<script lang="ts" setup>
import { CfgTypography, CfgTypographySizes } from '@cfgtech/ui'
import { InfoIcon } from '@cfgtech/icons'
import { useIssue } from '~/composables/issue/useIssue'
import { useStepper } from '~/composables/stepper/useStepper'
import { useOrder } from '~/composables/order/useOrder'
import { usePhoneClick } from '~/composables/gtm/usePhoneClick'
import { PhonePosition } from '~/composables/gtm/useDataLayer'

const { t } = useI18n()

const { contactPhone } = useApp()

const { issue } = useIssue()
const { step } = useStepper()
const { order } = useOrder()

const { handlePhoneClick } = usePhoneClick()

const sourceQuery = useRoute().query.src as string

const referrerText = computed(() => {
  if (order.value?.mediator === 'buffler') {
    return t('summary.referrers.buffler.description', { issueName: issue.value?.company?.name || '' })
  }

  if (sourceQuery === SourceQuery.dluhopisomat) {
    return t('summary.referrers.dmat.description', { issueName: issue.value?.company?.name || '' })
  }

  if (sourceQuery === SourceQuery.srov) {
    return t('summary.referrers.srov.description', { issueName: issue.value?.company?.name || '' })
  }

  return t('summary.referrers.all.description', { issueName: issue.value?.company?.name || '' })
})

const steps = computed(() => [
  { label: t('summary.steps.investment.label') },
  { label: t('summary.steps.contacts.label') },
  { label: t('summary.steps.personalData.label') },
  { label: t('summary.steps.result.label') },
])

const progress = computed(() => (step.value + 1) / steps.value.length)
</script>

<template>
  <UiPageBackground class="flex flex-1">
    <div class="flex flex-1 flex-col">
      <header class="sticky top-0 z-sticky shrink-0 bg-white py-6">
        <div class="container">
          <div class="flex items-center">
            <LayoutLogo />

            <div class="flex grow justify-end">
              <UiStepper :current-step-index="step" :steps="steps" />
            </div>
          </div>
        </div>

        <div class="header-stepper-bg absolute bottom-0 left-0 h-1 w-full">
          <span
            class="absolute bottom-0 left-0 block h-1 w-full origin-top-left bg-brand transition-transform duration-300"
            :style="{
              transform: `scaleX(${progress})`,
            }"
          />
        </div>
      </header>

      <main class="mt-10 grow md:mt-20">
        <div class="mx-auto max-w-[500px] px-5 text-center">
          <CfgTypography :size="CfgTypographySizes.h1" tag-name="h1">
            {{ $t('summary.title', { issueName: issue?.name || '' }) }}
          </CfgTypography>

          <CfgTypography class="mt-3 block text-grey-300" :size="CfgTypographySizes.md">
            {{ issue?.company.address.firstLine }}
            <br>
            {{ issue?.company.name }}
          </CfgTypography>
        </div>

        <div class="mx-auto mt-7 max-w-[740px] px-3 md:mt-10">
          <UiStyledBox v-slot="{ classes }" class="referrer-infobox">
            <CfgTypography
              class="flex gap-x-3"
              :class="classes"
              :size="CfgTypographySizes.md"
            >
              <InfoIcon class="shrink-0 rotate-180 text-[1.5rem] text-brand md:text-[1.7rem]" />

              <span>
                <span v-html="referrerText" />

                <a
                  class="text-brand underline"
                  :href="`tel:${contactPhone.original}`"
                  @click="handlePhoneClick(PhonePosition.MESSAGE)"
                  v-html="contactPhone.formatted"
                />
              </span>
            </CfgTypography>
          </UiStyledBox>

          <div class="mt-4">
            <slot />
          </div>
        </div>
      </main>

      <LayoutFooter class="mt-20 shrink-0 lg:mt-32" />
    </div>
  </UiPageBackground>
</template>

<style>
.layout-wrapper {
  background: #E5EBF9 url("/img/bg.png") no-repeat center center;
}
</style>
