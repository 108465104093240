<script lang="ts" setup>
import { CfgTypography, CfgTypographySizes } from '@cfgtech/ui'
import { usePhoneClick } from '~/composables/gtm/usePhoneClick'
import { PhonePosition } from '~/composables/gtm/useDataLayer'

const { appData, contactPhone } = useApp()

const { handlePhoneClick } = usePhoneClick()

function openCookieBot() {
  (window as any)?.Cookiebot?.renew?.()
}
</script>

<template>
  <footer class="bg-white py-14 lg:py-5">
    <div class="container">
      <div class="flex flex-col items-center gap-5 lg:flex-row">
        <LayoutLogo />

        <div class="flex grow justify-center gap-5 lg:gap-10">
          <a
            class="text-brand underline"
            :href="`tel:${contactPhone.original}`"
            @click="handlePhoneClick(PhonePosition.FOOTER)"
            v-html="contactPhone.formatted"
          />

          <a
            class="text-brand underline"
            href="#"
            @click.prevent="openCookieBot"
          >
            {{ $t('footer.cookie.label') }}
          </a>
        </div>

        <CfgTypography class="shrink-0 text-center text-grey-300 lg:text-left" :size="CfgTypographySizes.md">
          {{ appData.company.name }}
          <br>
          {{ appData.company.address }}
        </CfgTypography>
      </div>
    </div>
  </footer>
</template>
