<script lang="ts" setup>
import { toRefs, useArrayMap } from '@vueuse/core'
import { CfgTypography, CfgTypographySizes } from '@cfgtech/ui'

type Props = {
  currentStepIndex: number
  steps: Array<{ label: string }>
}

const props = defineProps<Props>()

const { steps, currentStepIndex } = toRefs(props)

const statefullSteps = useArrayMap(steps, (step, index) => ({
  ...step,
  isCurrent: index === currentStepIndex.value,
  isCompleted: index < currentStepIndex.value,
}))
</script>

<template>
  <nav aria-label="Progress">
    <ol class="flex gap-x-10 overflow-hidden">
      <li
        v-for="(step, index) in statefullSteps"
        :key="step.label"
        class="relative items-center gap-x-3"
        :class="{
          'hidden lg:flex': !step.isCurrent,
          'flex': step.isCurrent,
        }"
      >
        <CfgTypography
          class="flex size-8 items-center justify-center rounded-full font-highlighted"
          :class="{
            'bg-brand text-white': step.isCompleted,
            'border border-brand bg-brand/10 text-brand': step.isCurrent,
            'bg-grey-200/50 text-black': !step.isCurrent && !step.isCompleted,
          }"
          :size="CfgTypographySizes.sm"
        >
          {{ index + 1 }}
        </CfgTypography>

        <CfgTypography :size="CfgTypographySizes.sm">
          {{ step.label }}

          <small class="mt-0.5 block leading-none text-grey-200 lg:hidden">
            {{ $t('summary.stepsOf', { current: index + 1, total: steps.length }) }}
          </small>
        </CfgTypography>
      </li>
    </ol>
  </nav>
</template>
