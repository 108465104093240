import { RouteNamesEnum } from '../stepper/useStepper'
import type { PhonePosition } from './useDataLayer'
import { Location, useDataLayer } from './useDataLayer'

export function usePhoneClick() {
  const route = useRoute()

  const { onPhoneClick } = useDataLayer()

  function handlePhoneClick(position: PhonePosition) {
    switch (route.name) {
      case 'index':
        onPhoneClick(Location.SETUP, position)
        break

      case RouteNamesEnum.contacts:
        onPhoneClick(Location.CONTACTS, position)
        break

      case RouteNamesEnum.investor:
        onPhoneClick(Location.INVESTOR, position)
        break

      case RouteNamesEnum.result:
        onPhoneClick(Location.RESULT, position)
        break

      default:
        break
    }
  }

  return {
    handlePhoneClick,
  }
}
